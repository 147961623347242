import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import {
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  UnaryFunction,
  pipe,
  throwError,
  timer
} from 'rxjs';
import { RetryConfig, catchError, filter, map, tap } from 'rxjs/operators';
import { LivErrorResponse } from 'src/app/core/models/liv-response-protocol.model';

interface LivCatchErrorOptions {
  throw?: boolean;
}

export function filterResponse<T>(): UnaryFunction<
  Observable<HttpEvent<T>>,
  Observable<T>
> {
  return pipe(
    filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
    map((response: HttpResponse<T>) => response.body)
  );
}

export function uploadProgress<T>(
  cb: (progress: number) => void
): MonoTypeOperatorFunction<HttpEvent<T>> {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((event.loaded * 100) / event.total));
    }
  });
}

export function customCatchError<T>(
  cb: (error: LivErrorResponse['error']) => void,
  options: LivCatchErrorOptions = {
    throw: false
  }
): MonoTypeOperatorFunction<T> {
  return pipe(
    catchError((error: LivErrorResponse) => {
      cb(error?.error);

      if (options.throw) {
        return throwError(() => error);
      }

      return EMPTY;
    })
  );
}

export function shouldRetry({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = []
}: GenericRetryStrategy = {}): RetryConfig {
  return {
    count: maxRetryAttempts,
    delay(error, retryAttempt) {
      const err = error as HttpErrorResponse;
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.some((statusCode) => statusCode === err.status)
      ) {
        return throwError(() => err);
      }
      return timer(retryAttempt * scalingDuration);
    }
  } as RetryConfig;
}

type GenericRetryStrategy = {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
};
